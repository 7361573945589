import { Filter, FiltersObject } from '@/lib/types/filters';
import { ResultModelType, SlugItem } from '@/lib/types/models/common';

export default function useFilters(filtersObjectParam: FiltersObject): {
  isModalOpened: Ref<boolean>;
  isShowMobileMap: Ref<boolean>;
  filters: Ref<Filter[]>;
  filtersObject: Ref<FiltersObject>;
  results: Ref<ResultModelType[]>;
  isLoading: Ref<boolean>;
  handleToggleModal: (isOpen: boolean) => void;
  handleSetFilters: (newFilters: FiltersObject) => void;
  handleResetFilters: () => void;
  handleDeleteFilter: (filter: Filter) => void;
  onSimulateLoading: () => void;
  handleToggleShowMobileMap: () => void;
} {
  const isModalOpened = ref(false);
  const isShowMobileMap = ref(false);
  const filters = ref<Filter[]>([]);
  const filtersObject = ref<FiltersObject>(filtersObjectParam);
  const results = ref<ResultModelType[]>([]);
  const isLoading = ref(false);

  function handleToggleModal(isOpen: boolean): void {
    isModalOpened.value = isOpen;
  }

  function handleSetFilters(newFilters: FiltersObject): void {
    filtersObject.value = { ...newFilters };

    const result: Filter<string | SlugItem | boolean>[] = [];
    for (const value of Object.values(newFilters)) {
      if (value) {
        result.push(...(Array.isArray(value) ? value : [value]));
      }
    }

    filters.value = result as Filter[];
  }

  function handleResetFilters(): void {
    Object.keys(filtersObject.value).forEach(key => {
      if (Array.isArray(filtersObject.value[key])) {
        if ((filtersObject.value[key] as Filter[]).some(item => item.isDisabled)) {
          return;
        }
        filtersObject.value = { ...filtersObject.value, [key]: [] };
      } else {
        if ((filtersObject.value[key] as Filter).isDisabled) {
          return;
        }
        filtersObject.value = { ...filtersObject.value, [key]: null };
      }
    });
    filters.value = filters.value.filter(item => item.isDisabled);
  }

  function handleDeleteFilter({ identifier, value }: Filter): void {
    const filterValue = filtersObject.value[identifier];
    filtersObject.value = {
      ...filtersObject.value,
      [identifier]: Array.isArray(filterValue)
        ? filterValue.filter(item => item.value !== value)
        : null
    };
  }

  function onSimulateLoading(): void {
    isLoading.value = true;

    setTimeout(() => {
      isLoading.value = false;
    }, 1000);
  }

  function handleToggleShowMobileMap(): void {
    isShowMobileMap.value = !isShowMobileMap.value;
  }

  return {
    filters,
    filtersObject,
    results,
    isLoading,
    isModalOpened,
    isShowMobileMap,
    handleToggleModal,
    handleSetFilters,
    handleResetFilters,
    handleDeleteFilter,
    onSimulateLoading,
    handleToggleShowMobileMap
  };
}

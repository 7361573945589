/* *
 *
 * Composable used to fetch JOURNEYS, articles and events from Strapi depending on filters we apply
 *
 * If you want to add new filters, make sure to pass them to fitlersObject in the component
 * Then, add the filter to the setStrapiFilters function to handle these new filters to pass to Strapi
 * Make sure to set the filter to an e,pty object if its corresponding payload is not defined
 * Refer to the setStrapiFilters function to see how it's done
 *
 * Current supported filters:
 * - Travel style (Categories)
 * - Season (Periods)
 * - Duration (Days)
 * - Price
 *
 *
 *
 * @param {Ref<FiltersObject>} filtersObject - Object containing all the filters we apply
 * @param {keyof typeof loadingStore.$state} isLoadingContext - Context we want to use for the loading state stored globally in Pinia
 * @returns {Ref<Strapi4ResponseMany<Journey>>} resultsResponse - Response from Strapi
 * @returns {Ref<Strapi4RequestParams['filters']>} strapiFilters - Filters to pass to Strapi
 * @returns {void} setStrapiFilters() - Function that sets the filters we pass to Strapi depending on the filters we apply
 *
 */

import { populateAll, populateJourneyCountriesAndGeographicZones } from '@/lib/strapiFilters';

import { BackEndpoints } from '@/lib/types/enums/backEndpoints.enum';
import { FiltersObject } from '@/lib/types/filters';
import { ResultType } from '@/lib/types/models/common';
import { PaginationByOffset, Strapi4RequestParams, Strapi4ResponseMany } from '@/lib/types/strapi';

export default function useFetchFilters(
  filtersObject: Ref<FiltersObject>,
  isLoadingContext: any,
  pagination: Ref<PaginationByOffset>,
  filterSort: Ref<string[]>,
  resultType: 'journey' | 'article' | 'event' = 'journey',
  isCategoryPage: boolean = false
): {
  resultsResponse: Ref<Strapi4ResponseMany<ResultType> | undefined>;
  strapiFilters: Ref<Strapi4RequestParams['filters']>;
  isLoadingResults: ComputedRef<boolean>;
  isLoadingPagination: ComputedRef<boolean>;
  handlePaginate(): void;
} {
  const { setIsLoading, getIsLoading } = useLoadingStore();
  const { find } = useStrapi();
  const isLoadingResults = computed(() => getIsLoading(isLoadingContext));
  const isLoadingPagination = computed(() => getIsLoading('pagination'));

  const resultsResponse = ref<Strapi4ResponseMany<ResultType>>();
  const strapiFilters = computed<Strapi4RequestParams['filters']>(() =>
    computeStrapiFilter(filtersObject.value, resultType)
  );

  const initialFetchDone = ref(false);

  async function fetchResults(
    filters?: Strapi4RequestParams['filters'],
    sort?: string[]
  ): Promise<void> {
    if (resultType === 'journey') {
      resultsResponse.value = await find(BackEndpoints.JOURNEYS, {
        fields: [
          'title',
          'days',
          'slug',
          'duration',
          'generalInternalRef',
          'generalTravelType',
          'nights',
          'position',
          'price',
          'pricesBeforePrice',
          'imageSlugs'
        ],
        populate: populateJourneyCountriesAndGeographicZones,
        filters: {
          ...filters,
          generalTravelType: {
            $ne: 'extension'
          }
        },
        pagination: pagination.value,
        sort: [
          ...(sort ?? []),
          isCategoryPage ? 'categoriesPosition:asc' : 'position:asc',
          'title:asc'
        ]
      });
    } else if (resultType === 'event') {
      resultsResponse.value = await find(BackEndpoints.WP_EVENTS, {
        populate: populateAll,
        filters,
        pagination: pagination.value,
        sort
      });
    } else if (resultType === 'article') {
      resultsResponse.value = await find(BackEndpoints.ARTICLES, {
        populate: populateAll,
        filters,
        pagination: pagination.value,
        sort
      });
    }
  }

  async function handlePaginate(): Promise<void> {
    setIsLoading('pagination', true);
    await fetchResults(strapiFilters.value, filterSort.value);
    setIsLoading('pagination', false);
  }

  watch(
    [strapiFilters, filterSort],
    async () => {
      // TODO: filterSort should be integrated in strapiFilters so that we only watch on one object,
      // this would remove a duplicated call each time the sort changes
      if (!initialFetchDone.value || isLoadingResults.value) {
        return;
      }
      setIsLoading('gridResults', true);
      await fetchResults(strapiFilters.value, filterSort.value);
      setIsLoading('gridResults', false);
    },
    { deep: true }
  );

  onMounted(async () => {
    setIsLoading('gridResults', true);
    await fetchResults(strapiFilters.value, filterSort.value);
    setIsLoading('gridResults', false);
    initialFetchDone.value = true;
  });

  watch(resultsResponse, () => {
    setIsLoading('gridResults', false);
    setIsLoading('pagination', false);
  });

  return {
    resultsResponse,
    strapiFilters,
    isLoadingResults,
    isLoadingPagination,
    handlePaginate
  };
}
